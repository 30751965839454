'use client'

import {ChangeEvent, ReactNode, useTransition} from 'react';
import { useRouter, usePathname } from '../../navigation'

function LocaleSwitcherSelect({ children, defaultValue='?', label='yo' }) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();

  function onSelectChange(event) {
    const nextLocale = event.target.value;
    startTransition(() => {
      router.replace(pathname, {locale: nextLocale});
    });
  }

  return (
    <label
      className=
        'z-[1000] relative text-gray-400 transition-opacity [&:disabled]:opacity-30'
    >
      <p className="sr-only">{label}</p>
      <select
        className="inline-flex appearance-none bg-transparent py-3 pl-2 pr-6"
        defaultValue='en'
        disabled={isPending}
        onChange={onSelectChange}
      >
        {children}
      </select>
      <span className="pointer-events-none absolute right-2 top-[8px]">⌄</span>
    </label>
  );
}

export default LocaleSwitcherSelect