'use client'

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image from 'next/image'
import { useRouter } from '../../navigation.js';
// import { useRouter } from 'next/navigation';
import { useGlobalContext } from '@/context/context';
import axios from 'axios';
import {PulseLoader, ClipLoader} from 'react-spinners';
import LanguageToggle from '@/components/LanguageToggle';
import LocaleSwitcher from '@/components/locale-switcher/LocaleSwitcher.js';
import { useLocale, useTranslations } from 'next-intl';
import { loadScript } from "@paypal/paypal-js";
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Section = styled.section`
  height: 100vh;
  background-image: linear-gradient(rgba(45, 45, 45, 0.2), rgba(44, 44, 44, 0.1)), url('/img/outside-view-3.jpg');
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('/img/lobby.jpg'); */
  background-size: cover;
  background-position: center center;
`
const Container = styled.div`
color: white;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 3.3rem;
  h1 {
    text-align: center;
    /* margin-bottom: 2rem; */
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  img {
    margin-top: .8rem;
    margin-bottom: 1.5rem;
    width:150px;
  }
  
`

const LoginDiv = styled.div`
color: #ffffff;
  /* background: rgb(255, 255, 255, 0.15); //0.15
    backdrop-filter: blur(25px);
    border: 1px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.1);  */

  padding: 2.5rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 490px;
  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  label {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }
  input {
    border-radius: 5px;
    padding: 5px 10px;
    border: 1.5px solid black;
    color: black;
    margin-bottom: 1rem;
  }
`
const LoginBtn = styled.button`
    /* width: 108px; */
    width: fit-content;
    height: 50px;
    margin: 0 auto;
    outline: none;
    font-size: 1.2rem;
    border: none;
    padding: 10px 30px;
    color: white;
    border-radius: 0px;
    margin-top: .75rem;
    
    background-color: #6a6a6a;
    border: 1.2px solid #fff;
    border-radius: 5px;
    &:hover {
        background-color: #a8a8a8;
    }
    &:disabled {
        background-color: #6a6a6a;
        color: #999;
        border-color: #6a6a6a;
        /* cursor: not-allowed; */
    }
`

const GoBackBtn = styled.button`
    /* width: 108px; */
    width: fit-content;
    height: 50px;
    margin: 0 auto;
    outline: none;
    /* font-size: 1rem; */
    font-size: 1.2rem;
    border: none;
    padding: 10px 30px;
    color: white;
    border-radius: 0px;
    margin-top: .75rem;
    
    background-color: #6a6a6a;
    border: 1.2px solid #fff;
    border-radius: 5px;
    &:hover {
        background-color: #a8a8a8;
    }
    &:disabled {
        background-color: #6a6a6a;
        color: #999;
        border-color: #6a6a6a;
        /* cursor: not-allowed; */
    }
`

const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #ff9e9e;
    font-weight: 600;
  }
`

const LoaderDiv = styled.div`
  position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function Home() {
  const router = useRouter();
  const { user, setUser, admin, setAdmin, setPeriod, setYear, menuLoading, setMenuLoading, languageToggle } = useGlobalContext();
  const [promotionCode, setPromotionCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [adminDetected, setAdminDetected] = useState(false);
  const [adminSuspect, setAdminSuspect] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  const tHome = useTranslations("Home");
  const locale = useLocale();
  //UseRefs
  const promotionCodeRef = useRef(null);
  const adminPasswordRef = useRef(null);


  useEffect(() => {
		const timer = setTimeout(() => {
			setErrorMessage('');
		}, 5000);
		return () => clearTimeout(timer);
	}, [errorMessage]);



    const handleLogin = async (e) => {
      e.preventDefault();
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json', // Set the content type based on your data
            'Access-Control-Allow-Origin': '*', // Set the origin that's allowed
          },
        };
        setMenuLoading(true);
         const loginResponse = await axios.post(`/api/login`, { promotionCode }, config);
         const token = loginResponse.data.token;
          // console.log('RESPONSE', response);
          if (loginResponse.data.user) {
            setUser(loginResponse.data.user);
          }
          
          // Handle admin login procedure
          if (loginResponse.data.adminDetected === true) {
            //  setAdmin(loginResponse.data.admin);
            setAdminSuspect(loginResponse.data.admin);
            setAdminDetected(true);
            setMenuLoading(false);
            setErrorMessage('');
            setTimeout(() => {
              if (adminPasswordRef.current) {
                adminPasswordRef.current.focus();
              }
            }, 100)
            return;
          }

          setPromotionCode('');
         localStorage.setItem('token', token);
        //  localStorage.setItem('period', response.data.period);
        //  localStorage.setItem('year', response.data.year);
         setPeriod(loginResponse.data.period);
         setYear(loginResponse.data.year);
          const currentTimestamp = Date.now();
          const oneHourInMilliseconds = 60 * 60 * 1000; // 1 hour in milliseconds
          const tokenExpiration = currentTimestamp + oneHourInMilliseconds;
          localStorage.setItem('tokenExpiration', tokenExpiration.toString());
          router.push('/calendar');
          // setMenuLoading(false);
        } catch (error) {
          setMenuLoading(false);
          if (locale == 'fr') {
            setErrorMessage(error.response.data.messageFR);
          }
          if (locale == 'en') {
            setErrorMessage(error.response.data.messageEN);
          }
          console.warn('Error', error.response);
      }
  }

  const handleAdminLogin = async (e) => {
    e.preventDefault();
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json', // Set the content type based on your data
            'Access-Control-Allow-Origin': '*', // Set the origin that's allowed
          },
        };
        setMenuLoading(true);
         const response = await axios.post(`/api/login/check-admin-password`, { adminName: promotionCode, adminPassword }, config);
         const token = response.data.token;
         setAdmin(response.data.admin);

         setPromotionCode('');
         setAdminPassword('');
         localStorage.setItem('token', token);
         setPeriod(response.data.period);
         setYear(response.data.year);
          const currentTimestamp = Date.now();
          const oneHourInMilliseconds = 60 * 60 * 1000; // 1 hour in milliseconds
          const tokenExpiration = currentTimestamp + oneHourInMilliseconds;
          localStorage.setItem('tokenExpiration', tokenExpiration.toString());
          router.push('/calendar');
      } catch (error) {
          setMenuLoading(false);
          if (locale == 'fr') {
            setErrorMessage(error.response.data.messageFR);
          }
          if (locale == 'en') {
            setErrorMessage(error.response.data.messageEN);
          }
          console.error('Error', error.response);
        }
  }

  const goBackFunc = () => {
    setAdminDetected(false);
    setPromotionCode('');
    setAdminPassword('');
    setErrorMessage('');
    if (promotionCodeRef.current) {
      promotionCodeRef.current.focus();
    }
  }

  return (
    <>
    {/* <Loader loader1={true} /> */}
    <Section>
      <Container>
        <img src="/img/GreyRockLogo.png"/>
        <h1>Grey Rock VIP Hotel Bookings </h1>
        <LoginDiv className='login-div'>
          {/* {!adminDetected && ( */}
            <h2>{tHome("Welcome")}</h2>
          {/* // )} */}

          <div className='flex flex-col'>
          {
            adminDetected ? (
              locale === 'fr' ? (
                <div className='flex-col flex leading-[1.2rem] text-center mb-2'>
                  <label className='text-green-200 text-center'>{tHome('AdminLoginDetected.Part1')}</label>
                  <label className='text-green-200 text-center'>{tHome('AdminLoginDetected.Part2')}</label>
                </div>
              ) : (
                <label className='text-green-200 text-center'>{tHome('AdminLoginDetected')}</label>
              )
            ) : (
              <label className='text-center'>{tHome("EnterPromoCode")}</label>
            )
          }
          </div>

            {/* NAME FIELD */}
            <input className='w-full max-w-[290px]' onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (!adminDetected) {
                  handleLogin(e)
                } else {
                  handleAdminLogin(e)
                }
              }
            }} ref={promotionCodeRef} value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)} type='text' required />
          

          {adminDetected && (
            <>
            {/* PASSWORD */}
              <input className='w-full max-w-[290px]' onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (adminDetected) {
                    handleAdminLogin(e)
                  } else {
                    handleLogin(e)
                  }
                }
              }} ref={adminPasswordRef} value={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} type='password' required />
              </>
          )}

          <BottomDiv>
            <p>{errorMessage}</p>
            <LoginBtn disabled={menuLoading} onClick={(e) => {
              console.log("????");
              if (!adminDetected) {
                handleLogin(e)
              }
              if (adminDetected) {
                handleAdminLogin(e)
              }
            }}>
              {!menuLoading && (
                <span>{tHome("Login")}</span>
                )}
              {menuLoading && (
                <LoaderDiv>
                  <ClipLoader size={'1.5rem'} color="#a7ffed" /> 
              </LoaderDiv>
              )}
            </LoginBtn>
            {adminDetected && (
            <GoBackBtn onClick={(e) => goBackFunc()}>
              {tHome("GoBack")}
            </GoBackBtn>
            )}
          </BottomDiv>
        </LoginDiv>
      </Container>
    </Section>

    </>
  )
}